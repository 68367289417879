.comp {
    transition: font-weight 0.3s, color 0.3s, background-color 0.3s;
}

.view-input {
    border: none;
    padding: 0.5vmax;
    width: 85%;
    text-align: center;
}

.panel-label {
    font-size: 1vmax;
    color: #000;
    display: inline;
    margin-right: 0.5vmax;
}

.panel-input {
    border: none;
    /* padding: 0.25vmax; */
    font-size: 0.8vmax;
    height: 1.5vmax;
    /* width: 30%; */
    display: inline-block;
    /* background-color: #0F1108; */
    color: #000;
    border: solid 1pt #000;
    transition: color 0.1s, background-color 0.1s;
}

.panel-input:focus {
    /* background-color: #000; */
    color: #0F1108;
}

.panel-input-reverse {
    border: none;
    padding: 0.25vmax;
    font-size: 1vmax;
    height: 1vmax;
    width: 30%;
    display: inline-block;
    /* background-color: #000000; */
    color: #0F1108;
    border: solid 1pt #0F1108;
    transition: color 0.1s, background-color 0.1s;
}

.panel-input-reverse:focus {
    /* background-color: #0F1108; */
    color: #000000;
}

.panel-button {
    text-decoration: none;
    /* background-color: #0F1108; */
    color: #000000;
    display: inline-block;
    font-size: 1vmax;
    border: solid 1pt #000000;
    padding: 0.25vmax 0.5vmax;
    transition: color 0.1s, background-color 0.1s;
}

.panel-button:hover {
    /* background-color: #000000; */
    color: #0F1108;
    cursor: pointer;
}

.panel-button-two {
    text-decoration: none;
    /* background-color: #000000; */
    color: #0F1108;
    display: inline-block;
    font-size: 1vmax;
    border: solid 1pt #0F1108;
    padding: 0.25vmax 0.5vmax;
    transition: color 0.1s, background-color 0.1s;
}

.panel-button-two:hover {
    /* background-color: #0F1108; */
    color: #000000;
    cursor: pointer;
}

.panel-icon {
    font-size: 1vmax;
    margin-right: 0.75vmax;
    border: solid 1pt #000000;
    transition: box-shadow 0.1s, color 0.1s, background-color 0.1s;
    padding: 0.25vmax;
}

.panel-icon:hover {
    box-shadow: 0px 0px 15px #000000;
    cursor: pointer;
}

.panel-color-input {
    width: 3vmax;
    height: 1.5vmax;
}

input[type=color] {
    border: none;
    border-radius: 40px;
    background: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border: solid 1px #000;
    /*change color of the swatch border here*/
    border-radius: 40px;
}

.element {
    text-align: left;
}

.element p {
    font-weight: 300;
    padding-left: 0.5vmax;
    display: inline-block;
    margin-top: 0.5vmax;
    margin-bottom: 0.5vmax;
}

.element i {
    display: inline-block;
    font-size: 1vmax;
    padding-left: 1vmax;
}

.pointy:hover {
    cursor: pointer;
}

.panel-dropdown {
    border: none;
    padding: 0.25vmax;
    font-size: 1vmax;
    width: 30%;
    display: inline-block;
    /* background-color: #0F1108; */
    color: #000000;
    border: solid 1pt #000000;
    transition: color 0.1s, background-color 0.1s;
}

.panel-dropdown:hover {
    /* background-color: #000000; */
    color: #0F1108;
}

.color-pointy-light {
    transition: color 0.1s, background-color 0.1s;
}

.code-block {
    transition: background-color ease-in-out 0.1s, color ease-in-out 0.1s;
    /* background-color: #000; */
    color: #000000;
}

div.code-block:not(:has(div.code-block:hover)):hover {
    /* background-color: #1d0048; */
    color: #000;
    cursor: pointer;
}

/* some general transition classes (clean up these styles later) */

.dark-to-light-on-hover {
    color: #ffffff;
    /* background-color: #0F1108; */
    transition: color 0.1s, background-color 0.1s;
}

.dark-to-light-on-hover:hover {
    color: #ffffff;
    /* background-color: #000000; */
}

select {
    -webkit-appearance: menulist-button;
    appearance: menulist-button;
}