.ant-card-body {
    min-height: 50px;
    padding: 5px !important;
}

.task-list {
    margin: 10px;
    min-width: 290px;
    background-color: #ebecf0;
    height: max-content;
    min-height: 100px;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 1px #b4b1b1;
}

.list-title {
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
}

.list-container {
    padding: 5px;
    min-height: 60px;

    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
}

.list-item {
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 3px;
}

.task {
    font-size: 13px;
    margin-bottom: 8px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    width: 280px;
    box-shadow: 0 1px 0 #091e4240;
    text-align: justify;
}

.task-stats span {
    display: inline-block;
    width: 18px;
    height: 19px;
    background-color: red;
    color: #fff;
    margin-right: 5px;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    border-radius: 100%;
}

.task-stats .pending {
    background-color: #FFBF00;
}

.task-stats .due {
    background-color: red;
}

.task-stats .completed {
    background-color: green;
}

.create-list {
    font-size: 14px;
    cursor: pointer;
    min-height: auto;
    text-align: left;
}

.create-list .list-title {
    height: 50px;
    line-height: 35px;
}

.chk_progress {
    position: absolute;
    width: 99%;
    left: 5px;
    display: inline-flex;
    font-size: 10px;
    line-height: 25px;
    font-weight: bold;
}

.checklist {
    position: relative;

}

.checklist .ant-collapse-header {
    margin-top: 10px;
    padding-left: 5px !important;
}

.members .ant-list-item {
    cursor: pointer;
}

.board_tile .ant-card {
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #646161;
    border-color: transparent;
}

/* .board_tile .ant-card-cover {
    height: 125px;
} */

.board_tile .ant-card-cover img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.board_tile .ant-card-actions {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.item_detail_title {
    display: flex;
    padding: 10px 0px;

}

.item_detail_title span.anticon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.attachement_upload .ant-upload-list-item {
    display: none;
}

.attachement_upload .ant-upload-list-item-uploading {
    display: block;
    position: absolute;
    top: 0px;
    margin-left: 40px;
}

.comment-list {
    max-height: unset !important;
}

.comment {
    background-color: #fff;
    padding: 8px 5px;
    box-shadow: 0 1px 2px -1px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
    margin-right: 3px;
}

.ant-comment-inner {
    padding-bottom: 0px;
}

.ant-comment-content-author-name {
    font-weight: bold;
    color: #000;
}

.ant-comment-content-author-time {
    color: #5e6c84;
}

.ant-comment-actions {
    margin-top: 0px;
}

.cover-image {
    width: 100%;
    height: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.ant-card-meta-avatar {
    padding-right: 0px;
}

.ant-card-meta-title {
    font-weight: bold;
    padding-top: 5px;
}

.card_footer_container {
    display: flex;
    justify-content: space-between;
}

.card_due_date {
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
}


::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}