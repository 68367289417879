.ant-card-meta-description {
    min-height: 45px;
}

.ant-collapse-content {
    background-color: #fafafa;
}

.ant-collapse-item.ant-collapse-item-active {
    margin-bottom: 25px;
}

.ant-collapse {
    margin-bottom: 10px;
}

.ant-modal-content {
    background-color: #f4f5f7;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px;
}

/* Member Popover List Height */
.ant-list.ant-list-split {
    max-height: 300px;
    overflow-y: auto;
}

body,
.ant-layout {
    background-color: #e8ebed !important;
}

.ant-layout-header {
    line-height: 50px !important;
}

.ant-popover-inner-content {
    padding: 0px 16px !important;
}

.ant-menu-vertical {
    border-right: none !important;
}

.anticon {
    vertical-align: 1px;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #4443;
}

.ant-list-item-meta-title {
    text-transform: capitalize;
}