@import "./css-variables.scss";
@import '~antd/dist/antd.css';
@import "./boards.css";
@import "./board-list.css";
@import "./ant.override.css";

.filter-container {
    text-align: right;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 5px 20px;
}

body {
    background-color: #eff2f5;
}


#components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.2);
}

#root {
    height: 100%;
}

.site-layout,
.site-layout .site-layout-background {
    /* background: #e9ebed; */
    height: calc(100% - 5px);
    /* height: fit-content; */
    padding: 0px;
    margin-top: 50px;
    width: auto;
    min-height: auto;
}


.authentication-box {
    min-width: 100vw;
    min-height: 100vh;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/login_bg.jpeg");
    background-position: 50%;
    background-size: cover;
}

.authentication-box .container {
    max-width: 900px;
}

.card {
    margin-bottom: 10px;
    border: 0px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 8px;
    box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%);
}

.sign-in-class {
    font-weight: 800;
    margin-top: 10px;
    font-size: 20px;
}

.page-main-header {
    background-color: var(--color-primary);
    height: 50px;
    position: fixed;
    top: 0;
    z-index: 999;
    box-shadow: 0 0 10px 1px rgb(68 102 242 / 5%);
    width: 100%;
    display: flex;
    align-items: center;
    transition: 0.3s;
}

.main-header-right.row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    margin: 0;
}

.nav-right.col {
    text-align: right;
    padding-left: 0px;
}

.main-header-left.d-none.d-lg-block {
    display: inline-flex;
    width: 100%;
    height: 80px;
    padding: 12px;
    align-items: center;
    background: var(--color-primary);
    z-index: 10;
    box-shadow: -3px 1px 3px 1px rgb(68 102 242 / 10%);
}

.sidebar.custom-scrollbar {
    height: calc(100vh - 100px);
    overflow: auto;
    box-shadow: 0 0 11px rgb(143 164 232 / 8%);
    background: var(--color-primary);
}

ul.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 20px;
}

ul.sidebar-menu li {
    width: 100%;
    margin-bottom: 10px;
}

ul.sidebar-menu li h6 {
    color: #ffffff;
}

ul.sidebar-menu li a {
    text-decoration: none;
}

a.sidebar-header h6 {
    margin-top: 10px !important;
    font-weight: 600;
    color: #ffffff;
}

a.sidebar-header {
    font-size: 16px;
    letter-spacing: 0.5px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-transform: capitalize;
    font-weight: 600;
    color: #313131;
    list-style: none;
    text-decoration: none;
}

.navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.nav-item {
    padding: 5px 17px !important;
}

.page-main-header .main-header-right .nav-right>ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.page-main-header .main-header-right .nav-right>ul>li {
    position: relative;
    border-left: 1px solid #eff0f1;
    padding: 0 20px;
}

.user-menu {
    list-style: none;
    margin-bottom: 0px;
}

.nav-menus .onhover-dropdown {
    cursor: pointer;
    position: relative;
}

.page-main-header .main-header-right li {
    display: inline-block;
    position: relative;
}

.page-main-header .main-header-right .nav-right ul li svg {
    margin-top: 10px;
    width: 18px;
    height: 18px;
    color: #ffffff;
}

.page-main-header .main-header-right svg {
    color: #ffffff;
}

.nav-menus .notification-badge {
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 4px 7px;
}

.page-main-header .main-header-right .nav-right ul li .dot {
    width: 3px;
    height: 3px;
    border-radius: 30px;
    background-color: #00b4eb;
    position: absolute;
    right: 17px;
    bottom: 6px;
    animation: blink 1.5s infinite;
}

.media {
    display: flex;
    align-items: flex-start;
}

.img-50 {
    width: 50px !important;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation {
    position: relative;
    right: -3px;
    top: -7px;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #00b4eb;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: #00b4eb;
    position: absolute;
    right: 6px;
    top: -10px;
}

.page-main-header .main-header-right .nav-right>ul>li:last-child {
    border-left: none;
    padding-right: 0;
}

.align-items-center {
    align-items: center !important;
}

.pull-right {
    float: right;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
    right: -10px;
    left: inherit;
    width: 150px;
    top: 43px;
    visibility: visible !important;
    opacity: 3 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
    padding-top: 0;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
    display: block;
    text-align: left;
    padding-top: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a {
    color: #313131;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.onhover-show-div {
    top: 80px;
    position: absolute;
    z-index: 8;
    background-color: #ffffff;
    transition: all linear 0.3s;
}

.onhover-show-div {
    box-shadow: 0 0 2px 2px #f8f8f9;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
}

.page-wrapper .page-body {
    height: calc(100vh - 80px);
    min-height: 70vh;
    position: relative;
    margin: 0px;
}

.page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 80px);
    position: relative;
}

.board-cardbody {
    border-radius: 5px;
}

.board-card {
    border-radius: 5px !important;
    box-shadow: 0px 0px 5px 0px #ccc;
    position: relative;
    height: 125px;
    background-size: cover;
}

.board-card .card-footer {
    padding: 5px 10px !important;
    color: white;
    font-weight: 900;
    backdrop-filter: blur(5px);
}

/* .board-card .card-body {
    padding: 0px !important;
} */

.board-card .board-cardbody div {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #ffffff;
    width: 100%;
    background-color: rgb(0 0 0 / 60%);
    padding: 2px 5px;
    font-weight: bold;
    font-size: 12px;
    text-transform: capitalize;
}

.create-card-title {
    text-align: center;
    vertical-align: middle;
    line-height: 100px;
}

.board-row .board-col {
    cursor: pointer;
}

.create-form label {
    font-size: 14px;
    font-weight: 500;
}

.create-form .form-img img {
    width: 18%;
    margin: 2px;
}

.scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    padding: 0px !important;
    min-height: calc(100vh - 120px);
}

.scrolling-wrapper .cardlist-card {
    flex: 0 0 auto;
    width: 21%;
    margin: 5px 8px;
    display: block !important;
    height: max-content;
}

.card-header {
    border-bottom: 0px !important;
}

.card-footer {
    border-top: 0px !important;
    padding: 1px !important;
}

.card-footer .card {
    padding: 1px;
}

.scroll-card {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-card .card {
    padding: 3px 2px 2px 5px;
    margin: 10px 3px;
    z-index: 10;
    cursor: pointer;
}

.scroll-card .card h6 {
    font-weight: 500;
    font-size: 14px;
}

.create-cardlist {
    height: 60px;
    cursor: pointer;
}

.cardlist-form {
    height: 125px;
}

.create-list {
    font-size: 14px;
    cursor: pointer;
}

.vert-menu {
    position: absolute !important;
    top: 5px;
    right: 5px;
}

.vert-menu a {
    padding: 2px;
}

.comment_box {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 3px;
}

.comment_box .time {
    font-size: 11px;
    text-align: right;
}

.comment-input-container {
    padding: 8px 12px;
    position: relative;
    transition-duration: 85ms;
    transition-property: padding-bottom;
    transition-timing-function: ease;

    border-radius: 3px;
    box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
    margin: 4px 4px 12px 0;
}

.comment-input-container textarea {
    overflow: hidden;
    overflow-wrap: break-word;
    background: #fff !important;
    box-shadow: none;
    box-sizing: initial;
    margin: 0;
    padding: 0;
    resize: none;
    width: 100%;
}

.comment-controls {
    text-align: right;
}

.user-avatar {
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    border-radius: 100%;
    float: left;
    background: #0c6dfd;
    color: #fff;
    margin: 5px 5px 5px 0px;
}

.comment-input-container textarea,
.comment-input-container textarea:active,
.comment-input-container textarea:focus,
.comment-input-container textarea:hover {
    border: 0;
    box-shadow: none;
}

.right-close-float {
    float: right;
    right: 10px;
}

.right-box {
    margin-top: 25%;
}

.right-box .card {
    padding: 2px;
    font-size: 12px;
    background-color: var(--color-secondary);
    margin: 7px 0px;
    cursor: pointer;
}

.right-box .card span svg {
    height: 16px;
}

.card-popover-header .right {
    float: right;
}

.card-popover-header span svg {
    cursor: pointer;
    height: 20px;
}

.card-popover-body-row {
    margin: 8px;
    height: 300px;
    overflow-y: scroll;
}

.card-popover-body-row img {
    height: 35px;
    width: 35px;
}

.card-popover-body-row h6 {
    font-size: 14px;
    text-align: left;
}

.popover-body h5 {
    font-size: 14px;
    margin-bottom: 8px;
}

.float-left .board-list-heading {
    font-weight: 900;
    font-size: 16px;
    padding: 6px;
    color: black;
}

.cursor-pointer {
    cursor: pointer;
}

.labels-popover-body {
    margin: 8px;
    width: 17em;
    height: 30px;
    border-radius: 5px;
}

.avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.card-list-item {
    max-height: 55vh;
    overflow-y: scroll;
    padding: 5px !important;
}


.cardlist-card .card-title {
    padding: 10px;
}

.checklist-form {
    border: none;
}

.desc-normal {
    padding: 10px 12px;
    border: 1px solid #ccc;
}

.board-edit svg {
    margin-left: 10px;
    width: 16px;
}

.tbl-small th,
.tbl-small td {
    font-size: 12px;
}

.container-fluid {
    background-size: cover;
}

.card-heading label {
    font-size: 16px;
    font-weight: 900;
}

.card-icon-list span {
    margin-right: 5px;
}

.card-icon-list span svg {
    height: 14px;
    width: 14px;
}

.popover {
    max-width: 300px !important;
}

.without-bg-button {
    background: #fff !important;
    color: black !important;
    border: none !important;
}

.without-bg-button:focus {
    box-shadow: 0 0 0 0rem rgb(130 138 145 / 50%) !important;
}


.checklist-item .list-group-item {
    padding: 0 !important;
    border: 0px !important;
}

.checklist-item .list-group-item-heading {
    font-size: 16px !important;
    margin-bottom: 2px !important;
}

.checklist-item .list-group-item-text {
    margin-bottom: 8px !important;
}

.popover-button {
    padding: 10px;
    width: 100%;
    text-align: left !important;
    border: none !important;
    margin: 4px !important;
}

.list_item {
    cursor: pointer;
}

.popover-button svg {
    height: 16px;
    width: 16px;
}

.popover-button .card-popover-header span {
    font-size: 16px;
}

.popover-button:focus {
    box-shadow: 0 0 0 0rem rgb(130 138 145 / 50%) !important;
}

.checked_item {
    position: absolute;
    top: 16px;
    right: 6px;
}