.board_tile {
    margin-bottom: 20px;
}

.board_list {
    margin: 5px;
}

.board_list_cards {
    border-left: 8px solid !important;
    padding: 8px;
    cursor: pointer;
}

.board_list_cards.Low {
    border-left-color: #2096f3 !important;
}

.board_list_cards.Medium {
    border-left-color: #ffc007 !important;
}

.board_list_cards.High {
    border-left-color: #f44336 !important;
}

.item_menu {
    min-width: 150px;
}

.acordian_content {
    box-shadow: 0px 0px 2px 1px #ccc;
    padding-left: 10px;
    margin-bottom: 20px;
}

.heading {
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
}