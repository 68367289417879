@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap'); */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */


  font-family: 'Roboto Condensed', sans-serif;
  /* font-family: 'Nunito', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-color: #afffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-tree .ant-tree-treenode,
.form-tree .ant-tree-node-content-wrapper {
  width: 100%;
}




.folder-container {
  padding: 0.5rem 0.5rem;
  margin: auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
}

.card-panel {
  padding: 15px 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  background-color: #fff;
}

.card-panel:hover {
  background-color: rgb(204 204 204 / 60%);
}

.folder {
  width: 215px;
  display: inline-block;
  margin: 10px 20px 10px 0;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.folder span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 22px;
  margin-right: 5px;
}

span.folder-navigation {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 4px 20px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}


.blockUI {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 35%);
  z-index: 10000;
}


.blockUI .loading-icon-container {
  width: 200px;
  height: 100px;
  margin: 20% auto;
  text-align: center;
}

.blockUI .loading-icon-container span {
  font-size: 50px;
  color: #1777ff;
}

.blockUI .loading-icon-container strong {
  color: #fff;
}


.create-form-container {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  padding: 5px 10px;
  height: calc(100% - 60px);
}

.formDesignerBoundary {
  /* border-bottom: 2px solid red; */
  height: 268.66mm;
}

.boundaryInfo {
  background: red;
  margin-left: -55px;
  font-size: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 335mm;
}

.boundaryInfo:after {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0px 8px 10px;
  border-color: transparent transparent transparent red;
  right: -10px;
  top: 7.9px;
}

.form-designer {
  background-color: #e9ebed;
  width: 78%;
  padding: 5px;
  box-shadow: 0px 0px 5px #444;
  border-radius: 5px;
  background-image: url('./assets/images/form-bg-4.png');
  background-size: 10px;
  height: fit-content;
}

.form-control-box {
  width: 20%;
  height: calc(100% - 1px);
  background-color: #e9ebed;
  padding: 5px;
  box-shadow: 0px 0px 5px #444;
  border-radius: 5px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.form-control-box .card1 {
  height: calc(100% - 50%);
  margin-bottom: 5px;
}

.form-control-box .card2 {
  height: calc(100% - 60%);
  margin-bottom: 5px;
}

.form-control-box .ant-card .ant-card-head {
  height: 30px;
  font-weight: bold;
}

.form-control-box .ant-card .ant-card-head .ant-card-head-title {
  padding: 8px 0px;
}

.form-control-box .card1 .ant-card-body {
  height: calc(100% - 15%);
  overflow-y: auto;
  overflow-x: hidden;
}

.form-control-box .card2 .ant-card-body {
  height: calc(100% - 18%);
  overflow-y: auto;
  overflow-x: hidden;
}

.toolbar-element-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.toolbar-script-container a,
.toolbar-element-container .toolbar-element {
  border: 1px #FAF3DD solid;
  padding: 1vmax;
  color: #FAF3DD;
  background-color: black;
  transition: background-color 0.1s, color 0.1s;
  min-width: 120px;
  cursor: pointer;
  justify-content: space-evenly;
}

.toolbar-script-container a:hover,
.toolbar-element-container .toolbar-element:hover {
  color: black;
  background-color: #FAF3DD;
}

.form-designer-footer {
  background-color: #ccc;
  height: 110px;
  margin-top: 5px;
}

.stpc-modal a {
  color: #fff;
  background-color: #0f1108;
}

.stpc-modal a:hover {
  background-color: #feffdb;
  color: #000;
}

.stpc-modal .code-block {
  background-color: #000;
  color: #faf3dd !important;
  transition: background-color .1s ease-in-out, color .1s ease-in-out;
}

.stpc-modal div.code-block:not(:has(div.code-block:hover)):hover {
  background-color: #1d0048 !important;
  color: #feffdb;
  cursor: pointer;
}

.stpc-modal .panel-icon {
  border: 1pt solid #faf3dd;
  font-size: 1vmax;
  margin-right: 0.75vmax;
  padding: 0.25vmax;
  transition: box-shadow .1s, color .1s, background-color .1s;
}

/* .stpc-modal i {
  padding: 1vmax;
  display: inline-block;
  font-size: 1.15vmax;
  font-weight: 300;
  border-width: 0pt 0pt 0pt 1pt;
  border-top-style: initial;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: solid;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: rgb(250, 243, 221);
  border-image: initial;
  margin-left: 1vmax;
  background-color: rgb(0, 0, 0);
  color: rgb(250, 243, 221);
} */

.stpc-modal .panel-button {
  text-decoration: none;
  background-color: #0F1108;
  color: #FAF3DD;
  display: inline-block;
  font-size: 1vmax;
  border: solid 1pt #FAF3DD;
  padding: 0.25vmax 0.5vmax;
  transition: color 0.1s, background-color 0.1s;
}

.stpc-modal .panel-button:hover {
  background-color: #FAF3DD;
  color: #0F1108;
  cursor: pointer;
}


.board-card .ant-card-actions li {
  margin: 0px;
}